


























































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { invitationController } from './invitation-controller'

@Observer
@Component
export default class extends Vue {
  controller = invitationController

  dateMin = moment().format('YYYY-MM-DD')

  datePicker = null
  timePicker = null
}
